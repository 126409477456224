.file {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.button {
    font-size: 12px;
    color: white;
    display: inline-block;
    cursor: pointer;
    display: inline-block;
    background-color: #004b83;
    /* border: 0.1vw solid rgba(172, 9, 9, 0.7); */
    /* border-radius: 2vw; */
    white-space: nowrap;
    margin: 0.1vw 0vw;
    padding: 10px;
}

.button:focus,
.button:hover {
    opacity: 0.8;
    outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}