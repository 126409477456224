body {
    font-family: 'Inter';
}

.main {
    width: 400px;
    height: 470px;
    margin: 7em auto;
    /* border-radius: 1.5em; */
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);

}

.passwordReset {
    padding-top: 40px;
    color: #004b83;
    font-family: 'Inter';
    font-weight: bold;
    font-size: 23px;
    text-align: center;
}

.un {
width: 76%;
color: rgb(38, 50, 56);
font-weight: 700;
font-size: 14px;
letter-spacing: 1px;
background: rgba(136, 126, 126, 0.04);
padding: 10px 20px;
border: none;
/* border-radius: 20px; */
outline: none;
box-sizing: border-box;
border: 2px solid rgba(0, 0, 0, 0.02);
margin-bottom: 50px;
margin-left: 46px;
text-align: center;
margin-bottom: 27px;
font-family: 'Inter';
}

form.form1 {
    padding-top: 40px;
}

form.form2 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.pass {
width: 76%;
color: rgb(38, 50, 56);
font-weight: 700;
font-size: 14px;
letter-spacing: 1px;
background: rgba(136, 126, 126, 0.04);
padding: 10px 20px;
border: none;
/* border-radius: 20px; */
outline: none;
box-sizing: border-box;
border: 2px solid rgba(0, 0, 0, 0.02);
margin-bottom: 50px;
margin-left: 46px;
text-align: center;
margin-bottom: 27px;
font-family: 'Inter';
}


.un:focus, .pass:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
    
}

.submitNewPassword {
  cursor: pointer;
    /* border-radius: 5em; */
    color: #fff;
    background:  #004b83;
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Inter';
    margin-left: 27.5%;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.submitVerif {
    cursor: pointer;
      /* border-radius: 5em; */
      color: #fff;
      background: #004b83;
      border: 0;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 10px;
      padding-top: 10px;
      font-family: 'Inter';
      margin-left: 24%;
      font-size: 13px;
      box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
  }

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #004b83;
    padding-top: 15px;
}

.error {
    text-align: center;
    color: red;
    font-family: 'Inter';
    font-size: 12px;
}

.message {
    text-align: center;
    color: red;
    font-family: 'Inter';
    font-size: 12px;
}

a {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #004b83;
    text-decoration: none
}

@media (max-width: 600px) {
    .main {
        border-radius: 0px;
    }
}