.main-sorry {
    /* height: ; */
    margin: 7em auto;
    font-family: 'Inter', sans-serif;
    width: 30%;
    /* height: 470px; */
    padding-top: 1vw;
    padding-bottom: 2vw;
    padding-left: 5vw;
    padding-right: 5vw;
    margin: 7em auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    vertical-align: center;
    position: relative;
    top: -4vw;
 }
 #sorry-img {
    width: 100%;
 }