.sideBarColor {
    background-color: #004b83 !important;
}

.ca-guide-container {
   width: 60vw;
   margin-left: calc(20vw);
   margin-right: calc(20vw);
}


.main-help h2 {
    font-family: 'Inter-Bold', serif;
    font-size: 35px;
    font-weight: 500;

 }

 .main-help {
   margin-top: 100px;
}


 .guide-body h2 {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 23px;
    font-weight: normal;
    text-align: left;
 }

 .guide-body p {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
 }

 .guide-body li {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
 }

 .guide-body h1 {
    font-family: 'Inter-Bold', serif;
    font-size: 30px !important;
    font-weight: 500;
    color: black;
    text-transform: uppercase;
 }

 .guide-body {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 16px;
    font-weight: lighter;
 }

 .main-help a {
     color: #614201;
 }

 .sideBar {
    position: fixed;
    float: left;
    height: 100%;
    top: 65px;
 }

 /* .main-help p {
    text-align: left;
    vertical-align: left;
 } */

#table-of-contents {
    /* position: -webkit-sticky; */
    position: fixed;
    left:2.5vw;
    top: 100px;
    /* top: calc(5vw+65px); */
    background: #f1cb77;
        /* height: ; */
        /* margin: 7em auto; */
        font-family: 'Inter', sans-serif;
        width: 12vw;
        /* height: 470px; */
        /* padding-top: 1vw; */
        /* padding-bottom: 2vw; */
        padding-left: 1vw;
        padding-right: 1vw;
        /* margin: 7em auto; */
        border-radius: 1em;
        /* box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.3); */
        text-align: left;
        vertical-align: center;
        font-size: 1vw;
}

#table-of-contents a {
    color: #444444;
}

.p2 {
    font-size: 0.85em;
    font-weight: 100;
    position: relative;
    /* right: 0.25vw; */
    font-style: oblique;
}

#table-of-contents p {
    /* color: #614201; */
    /* font-weight: 400; */
    text-align: left;
    vertical-align: right;
}

#WHTtableContainer {
   padding: 0 18px;
   max-height: 0px;
   overflow: hidden;
   transition: max-height 0.5s ease-out;
}

#WHTtableToggle {
   display: flex;
   align-content: center;
   margin: auto;
}

ul.toggleWHTtable {
   list-style: none;
   font-size: 0.8vw;
   
}
ul.toggleWHTtable li{
 display: inline-block;
 
}
ul.toggleWHTtable li label{
   display: inline-block;
   font-family: 'Inter';
   /* background-color: rgba(0, 0, 0, 0.8); */
   background-color: #004b83;
   padding: 12px;
   border: 0vw solid rgba(0, 0, 0, 0.5);
   color: white;
   /* border-radius: 2vw; */
   white-space: nowrap;
   margin: 0.1vw 0vw;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-tap-highlight-color: transparent;
   transition: transform .2s;
}

.rightButton {
   right: 2vw;
   position: absolute;
}



ul.toggleWHTtable li label {
   /* padding: 0.5vw 0.75vw; */
   cursor: pointer;
}

ul.toggleWHTtable li label::before {
   display: inline-block;
   font-style: normal;
   font-variant: normal;
   text-rendering: auto;
   -webkit-font-smoothing: antialiased;
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   font-size: 1vw;
   padding: 0.1vw 0.3vw 0.1vw 0.1vw;
   content: "\f204";
   transition: transform .3s ease-in-out;
}

ul.toggleWHTtable li :hover {
   opacity: 0.8;
}

ul.toggleWHTtable li input[type="checkbox"]:checked + label::before {
   font-family: "Font Awesome 5 Free";
   content: "\f205";
   transition: transform .3s ease-in-out;
}

ul.toggleWHTtable li input[type="checkbox"]:checked + label {
   background-color: #004b83;
   transition: transform .2s ease-in-out;
   content: "\f00c";
}

ul.toggleWHTtable li input[type="checkbox"] {
 display: absolute;
 background-color: #004b83;

}
ul.toggleWHTtable li input[type="checkbox"] {
 position: absolute;
 opacity: 0;
}
ul.toggleWHTtable li input[type="checkbox"]:focus + label {
 border: 0.1vw solid #888888;
}