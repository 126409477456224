.red-note
{        
    font-style: italic;
    color: #e81313;
}

li:not(:last-child) 
{
    margin-bottom: 10px;
}

table
{
    border-collapse: collapse;        
}

td, th
{
    border: 1px solid black;
    text-align: center;
    height: 3vh;
}

table.mt5-gateways th
{
    background-color: black;
    color: white;
}

.download-button {
    font-family: 'Inter';
    font-size: 18px;
    padding: 5px 10px ;
    /* position: absolute; */
    /* opacity: 0; */
    background-color: #004b83;
    color: white;
    border: none;
    cursor: pointer;
}

.download-button:hover {
    opacity: 0.8;
}

.dl-blurb {
    font-family: 'Inter';
    font-size: 15px;
    padding: 1em 2em;
    display: inline;
}

.dl-section {
    margin-bottom: 20px;
}

.summary {
    width: 45.5vw;
    cursor: pointer;
}


.tableHolderChangelog {
    padding-top:10px;
    display: flex;
    margin: 0 auto;
    width: 50vw;
}


.TableTimestamp {
    color: gray;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ReactTable {
    margin: 1vw;
    border-width: 0px !important;
    width: 105%;
}

.ReactTable .rt-table {
    border: 1px solid rgba(0,0,0,0.1);
}

.ReactTable .rt-td {
    white-space: normal !important
}

.ReactTable .rt-thead .rt-resizable-header-content {
    white-space: pre-line !important;
    word-wrap: break-word;
}

.ReactTable .rt-tr .action {
    transition: all .2s ease;
    text-align: center;
    color: red;
    transform: scale(0);
}

.ReactTable .rt-tr:hover .action {
    transform: scale(1.3);
}

.ReactTable .rt-tr:hover .rt-td {
    background: rgb(0,0,0,0.1);
}


.container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8vw;
    align-content: center;
    margin: auto;
}

.searchButton:hover {
    cursor:pointer;
    -webkit-mask-image: -webkit-gradient(linear, left center, right center, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0.8)));
    background-color: #333333;
}



.searchChangelog {
    width: 20vw;
    margin: 30px auto;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 0.1vw solid #333333;
    border-right: none;
    padding: 0.3vw;
    height: 1.5vw;
    /* border-radius: 0.5vw 0 0 0.5vw; */
    outline: none;
    color: #333333;
    font-size: 0.75vw;
  }
  
  .searchTerm:focus{
    color: #333333;
  }
  
  .searchButton {
    width: 3vw;
    /* height: 2.35vw; */
    border: 0.1vw solid #333333;
    background: #333333;
    text-align: center;
    color: #fff;
    /* border-radius: 0 0.5vw 0.5vw 0; */
    font-size: 1vw;
    cursor: default;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    width: 20vw;
    margin: 0 auto;
    align-content: center;
    padding-bottom: 1vw;
  }


.ReactTable .-pagination input {
    width: 2vw !important;
    font-size: 0.9vw !important;
}
/* .ReactTable .-pagination .-pageJump input {
    width: 70px;
    text-align: center;
} */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}
/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0.5vw rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 0.5vw;
}

::-webkit-scrollbar
{
	width: 0.5vw;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 0.5vw;
  background-color: #333333;
} */


@media only screen and (max-width: 600px) {
    

    .container {
        font-size: 2.5vw;
    }

   
    .searchTerm {
        border: 0.3vw solid #333333;
        padding: 0.6vw;
        height: 3.5vw;
        border-radius: 1vw 0 0 1vw;
        font-size: 2vw;
    }

    .wrap {
        width: 50vw;
        padding-bottom: 3vw;
    }

    .searchButton {
        width: 6vw;
    }

    
    .details {
        width:95vw;
    }
    
    .summary {
        width: 95vw;
        cursor: pointer;
    }
    
    
    .tableHolder {
        display: inline-block;
    }

    .ReactTable {
        font-size: 2vw;
    }

    .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
        max-width: 60px !important;
    }

    .ReactTable .rt-tbody .rt-td {
        max-width: 60px !important;
    }
}

input[type="file"] {
    display: none;
}

.csv-reader-button {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 0.25vw 0.5vw;
    cursor: pointer;
}