.App-body {
  background-color: white;  
  display:flex;
  flex-direction: column;
  align-items: center;  
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;  
  font-size: 13px;
}

.num-neg {
    color: red;    
    text-align: right;
}

.num {
    text-align: right;
}

#fullpage-loader .-loading {
  z-index: 100;
  position: absolute;
  height: calc(100vh - 65px);
  width: 100vw;
  background: transparent;
  pointer-events: none;
}

#fullpage-loader .-loading-inner {
  flex-grow: 1 !important;
  height: 100%;
  display: flex !important;
  background: rgba(255,255,255,0.8);
  flex-direction:column;
  transition: none !important;
  /* position: absolute; */
  top: -50% !important;
  justify-content: center;
  align-items: center;
}

#fullpage-loader .-loading-gif {
  width: 200px;
  height: 200px;
}

@font-face {
	font-family: 'Inter-Bold';
	src: url('files/Inter-Bold.ttf');
	src: local('Inter-Bold'), local('Inter-Bold'), url('files/Inter-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	src: url('files/Inter-Regular.ttf');
	src: local('Inter-Regular'), local('Inter-Regular'), url('files/Inter-Regular.ttf') format('truetype');
}
