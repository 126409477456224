#b-table-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#benchmarks-table {
    width: 35vw;
}
/*
#md-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

#spacing {
    height: 5vh;
}

