#ftEmbedForm {
    align-content: center;
    align-self: center;
    text-align: center;
    background: url(../images/26d-loading.gif) top center no-repeat;
    /* width:100vw; */
}

#frame {
    width:100%;
    height: 2000px;
}