.drop-link-button {
    border: none;
    cursor: pointer;
}

.Navbar-link-dropdown {
    position: relative;
    font-family: 'Inter';
    padding-left: 1vw;
    padding-right: 1vw;
    color:white   ;
    text-decoration: none;
    font-size: 16px;
    height: 65px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.drop-link-content {
    display: none;
    position: absolute;
    background-color: #041144;
    flex-direction: column;
    right: 0;
    top: 65px;
    min-width: 160px;
}

.drop-link-content a:hover {background-color: #004b83; color: white;}

.drop-link-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

.Navbar-link-dropdown:hover .drop-link-content {
    display: block;
  }

.fixed-dropdown {
    position: relative;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.fixed-dropdown-content {
    display: none;
    position: absolute;
    background-color: #041144;
    flex-direction: column;
    right: 0;
    top: 65px;
  }

  .fixed-dropdown-content a {
    color: white;
    text-decoration: none;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* Change color of dropdown links on hover */
.fixed-dropdown-content  a:hover {background-color: #004b83; color: white; display: block}

.fixed-dropdown-content :hover {
    display: block;
}

/* Show the dropdown menu on hover */
.fixed-dropdown:hover + .fixed-dropdown-content, .fixed-dropdown-content:hover {display: block;}

.Navbar {
    border-bottom: 5px solid #be5229;
    display: flex;
    flex-direction: row;
    background-color: #041144;
    color: white;
    align-items: center;
    height: 65px;
    justify-content: space-between;
    width: 100%;    
    position: sticky;
    top: 0px;
    z-index: 100000000;
}

.Navbar-logo {
    /* padding-inline-start: 1vw; */
    height: 40px;
    position: absolute;
    left: 20px;
}

.Navbar-logo:hover {
    cursor: pointer;
}

.Navbar-link-fixed-dropdown {
    font-family: 'Inter';
    /* padding-inline-start: 1vw;
    padding-inline-end: 1vw; */
    padding-left: 1vw;
    padding-right: 1vw;
    color:white   ;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Maybe updated the font-size to scale on width??? */
}

.Navbar-link {
    font-family: 'Inter';
    /* padding-inline-start: 1vw;
    padding-inline-end: 1vw; */
    padding-left: 1vw;
    padding-right: 1vw;
    color:white   ;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    /* Maybe updated the font-size to scale on width??? */
}

.Navbar-link:hover {
    opacity: 0.6;
}

.Navbar-active {
    text-decoration: underline;
}

.Navbar-spacer {
    flex-grow: 1;
}


@media only screen and (max-width: 600px) {
    .Navbar {
        width: 100vw; 

    }

    .Navbar-link { 
        font-size: 3vw;
  }

}