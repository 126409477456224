.dateSelector {   
    font-weight: bold;
    justify-content: center;
    float: initial;
    display: flex;     
    text-align: center;
    font-size: 1vw;
}

.start-date {
    padding-top: 3vh;
    display: flex;
    justify-content: space-between;

}

.end-date {
    padding-top: 3vh;
    display: flex;
    justify-content: space-between;
}

.startDate {
    display:flex;
    flex-direction:column;
    margin: 0.5vw;
    padding-top: 0.2vw;
}

.endDate {
    display:flex;
    flex-direction:column;
    margin: 10px;
}

.details {
    width: 28vw;
    padding-left: 5vw;
}

.summary {
    width: 53vw;
    cursor: pointer;
}


.tableHolder {
    margin-left: 5vw;
    margin-right: 10vw;
    display: flex;
    float: left;
    align-items: flex-start;
}

.PositionsTable {
    /* width: 55vw; */
    color: black;    
    display: flex;
    float:left;
}

.TableTimestamp {
    color: gray;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ReactTable {
    margin: 1vw;
    border-width: 0px !important;
    width: 100%;
    font-family: 'Inter';
    font-size: 12px;
}

#fullpage-loader .-loading {
    z-index: 100;
    position: absolute;
    height: calc(100vh - 65px);
    width: 100vw;
    background: transparent;
    pointer-events: none;
}

#fullpage-loader .-loading-inner {
    flex-grow: 1 !important;
    height: 100%;
    display: flex !important;
    background: rgba(255,255,255,0.8);
    flex-direction:column;
    transition: none !important;
    /* position: absolute; */
    top: -50% !important;
    justify-content: center;
    align-items: center;
}

#fullpage-loader .-loading-gif {
    width: 200px;
    height: 200px;
}


.ReactTable .-loading {
    background: transparent !important;
    pointer-events: none !important;
}

.ReactTable .-loading-inner {
    flex-grow: 1 !important;
    height: 100%;
    display: flex !important;
    flex-direction:column;
    background: rgba(255,255,255,0.8);
    transition: none !important;
    /* position: absolute; */
    top: -50% !important;
    justify-content: center;
    align-items: center;
}

.ReactTable .-loading-gif {
    width: 150px;
    height: 150px;
}

.ReactTable .rt-table {
    border: 1px solid rgba(0,0,0,0.1);
}

.ReactTable .rt-thead .rt-tr {
    background: black;
    color: white;
    font-weight: 600;
    font-family: 'Inter';
    font-size: 16px;
}

.ReactTable .rt-thead .rt-tr .rt-th {
    padding: 10px;   
}

.ReactTable .rt-thead .rt-resizable-header {
    font-family: 'Inter';
    font-weight: bold;
    font-size: 12px;
    background: #004b83;
    color: white;
    padding: 5px;
}

.ReactTable .rt-td {
    white-space: normal !important;
    padding: 5px;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    white-space: pre-line !important;
    word-wrap: break-word;
}

.ReactTable .rt-tr .action {
    transition: all .2s ease;
    text-align: center;
    color: red;
    transform: scale(0);
}

.ReactTable .rt-tr:hover .action {
    transform: scale(1.3);
}

.ReactTable .rt-tr:hover .rt-td {
    background: rgb(0,0,0,0.1);
}

#admin-only {
    font-family: 'Inter';
    /* border-radius: 3vw; */
    /* border-style: solid; */
    /* border-width: 0.15vw; */
    border: none;
    cursor: pointer;
    line-height: 163%;
    /* margin: 0 0 1.25rem; */
    text-align: center;
    display: inline-block;
    /* padding: 0.5rem 1rem 0.5rem; */
    /* font-size: 1vw; */
    background-color: #004b83;
    /* border-color: rgb(255, 0, 0); */
    color: white;
    padding: 12px;
    font-size: 16px;
    /* font-weight: bold; */
    /* font-size: 20px; */
    position: absolute;
    right: 2vw;
    z-index: 10;
    display: none;
}

#admin-only:hover {
    opacity: 0.95;
}

#admin-only::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    /* font-weight: 900; */
    /* font-size: ; */
    padding-right: 0.5vw;
    content: "\f044";
    transition: transform .3s ease-in-out;
}

#admin-only:hover::before {
    font-weight: 900;
}

#email-admin-only {
    font-family: 'Inter';
    /* border-radius: 3vw; */
    /* border-style: solid; */
    /* border-width: 0.15vw; */
    border: none;
    cursor: pointer;
    line-height: 163%;
    /* margin: 0 0 1.25rem; */
    text-align: center;
    display: inline-block;
    /* padding: 0.5rem 1rem 0.5rem; */
    /* font-size: 1vw; */
    background-color: #004b83;
    /* border-color: rgb(255, 0, 0); */
    color: white;
    padding: 12px;
    font-size: 16px;
    /* font-weight: bold; */
    /* font-size: 20px; */
    position: absolute;
    right: 38vw;
    z-index: 10;
    display: none;
}

#email-admin-only:hover {
    opacity: 0.95;
}

#email-admin-only::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    /* font-weight: 900; */
    /* font-size: ; */
    padding-right: 0.5vw;
    content: "\f1d8";
    transition: transform .3s ease-in-out;
}

#email-admin-only:hover::before {
    font-weight: 900;
}

#right-csv-button {
    display: flex;
    align-content: center;
    margin: auto;
}

#read-csv-button {
    display: flex;
    align-content: center;
    margin: auto;
}

.filters {
    align-content: center;
    margin: auto;
    display: flex;
}

.container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8vw;
    align-content: center;
    margin: auto;
}

.searchButton:hover {
    cursor:pointer;
    -webkit-mask-image: -webkit-gradient(linear, left center, right center, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0.8)));
    background-color: #333333;
}

ul.ks-cboxtags li label:hover {
    opacity: 0.8;
}

ul.ks-cboxtags {
    list-style: none;
    padding: 1vw;
}
ul.ks-cboxtags li{
  display: inline-block;
}
ul.ks-cboxtags li label{
    display: inline-block;
    background-color: rgba(255, 255, 255, .9);
    border: 0.1vw solid rgba(139, 139, 139, .3);
    color: #adadad;
    border-radius: 2vw;
    white-space: nowrap;
    margin: 0.1vw 0vw;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: transform .2s;
}

ul.ks-cboxtags li label {
    padding: 0.4vw 0.6vw;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 1vw;
    padding: 0.1vw 0.3vw 0.1vw 0.1vw;
    content: "\f067";
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
    border: 0.1vw solid #333333;
    background-color: #004b83;
    color: #fff;
    transition: transform .2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
    font-family: 'Inter';
  display: absolute;
  background-color: #004b83;

}
ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  background-color: #004b83;

}
ul.ks-cboxtags li input[type="checkbox"]:focus + label {
  border: 0.1vw solid #888888;
  background-color: #004b83;

}

/* Next section is for the download buttons */

ul.ks-cboxtags-2 {
    list-style: none;
    font-size: 0.8vw;
    
}
ul.ks-cboxtags-2 li{
  display: inline-block;
  
}
ul.ks-cboxtags-2 li label{
    display: inline-block;
    font-family: 'Inter';
    /* background-color: rgba(0, 0, 0, 0.8); */
    background-color: #004b83;
    padding: 12px;
    border: 0vw solid rgba(0, 0, 0, 0.5);
    color: white;
    /* border-radius: 2vw; */
    white-space: nowrap;
    margin: 0.1vw 0vw;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: transform .2s;
}

.rightButton {
    z-index: 1;
    right: 2vw;
    position: absolute;
}



ul.ks-cboxtags-2 li label {
    /* padding: 0.5vw 0.75vw; */
    cursor: pointer;
}

ul.ks-cboxtags-2 li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 1vw;
    padding: 0.1vw 0.3vw 0.1vw 0.1vw;
    content: "\f019";
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags-2 li :hover {
    opacity: 0.8;
}

ul.ks-cboxtags-2 li input[type="checkbox"]:checked + label::before {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags-2 li input[type="checkbox"]:checked + label {
    background-color: #004b83;

    /* font-family: "Font Awesome 5 Free"; */

    /* background-color: #004b83; */
    /* color: black; */
    transition: transform .2s ease-in-out;
    content: "\f00c";
}

ul.ks-cboxtags-2 li input[type="checkbox"] {
  display: absolute;
  background-color: #004b83;

}
ul.ks-cboxtags-2 li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
ul.ks-cboxtags-2 li input[type="checkbox"]:focus + label {
  border: 0.1vw solid #888888;
}


/* Next section is for the CSV import button */

ul.csv-import {
    list-style: none;
    font-size: 0.8vw;
    margin: 10px auto;
    padding-inline-start: 0px;
}
ul.csv-import li{
  display: inline-block;
  background-color: #004b83;
  /* border: 0.1vw solid rgba(172, 9, 9, 0.7); */
  /* border-radius: 2vw; */
  white-space: nowrap;
  margin: 0.1vw 0vw;
  padding: 2px;
}
ul.csv-import li label{
    display: inline-block;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: transform .2s;
}

ul.csv-import li label {
    padding: 0.5vw 0.75vw;
    cursor: pointer;
}

ul.csv-import li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 1vw;
    padding: 0.1vw 0.3vw 0.1vw 0.1vw;
    content: "\f574";
    transition: transform .3s ease-in-out;
}

ul.csv-import li :hover {
    opacity: 0.8;
}

ul.csv-import li input[type="checkbox"]:checked + label::before {
    font-family: "Font Awesome 5 Free";
    content: "\f574";
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
}

ul.csv-import li input[type="checkbox"]:checked + label {
    border: 0.1vw solid #333333;
    /* font-family: "Font Awesome 5 Free"; */

    /* background-color: #004b83; */
    /* color: black; */
    transition: transform .2s ease-in-out;
    content: "\f574";
}

ul.csv-import li input[type="checkbox"] {
  display: absolute;
}
ul.csv-import li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
ul.csv-import li input[type="checkbox"]:focus + label {
  border: 0.1vw solid #888888;
}

.search {
    width: 20vw;
    margin-left: 20vw;
    display: inline-flex;
    align-content: center;
  }
  
  .searchTerm {
    width: 100%;
    border: 0.1vw solid #333333;
    border-right: none;
    padding: 0.3vw;
    height: 1.5vw;
    /* border-radius: 0.5vw 0 0 0.5vw; */
    outline: none;
    color: #333333;
    font-size: 0.75vw;
  }
  
  .searchTerm:focus{
    color: #333333;
  }
  
  .searchButton {
    width: 3vw;
    /* height: 2.35vw; */
    border: 0.1vw solid #333333;
    background: #333333;
    text-align: center;
    color: #fff;
    /* border-radius: 0 0.5vw 0.5vw 0; */
    font-size: 1vw;
    cursor: default;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    width: 20vw;
    margin: 0 auto;
    align-content: center;
    padding-bottom: 1vw;
  }

  .flatpickr-input[readonly] {
    cursor: pointer;
    /* font-family: 'Inter', sans-serif; */
    font-size: 1vw;
}

.flatpickr-input {
    width: 102%;
    border: 0.1vw solid #333333;
    /* border-right: none; */
    padding: 0.5vw;
    height: 1vw;
    /* border-radius: 0.5vw 0.5vw 0.5vw 0.5vw; */
    outline: none;
    color: #666666;
}

.flatpickr-day.selected, .flatpickr-day.startRange {
    background: #004b83 !important;
    border-color: #004b83 !important;
}

.flatpickr-day.endRange {
    background: #004b83 !important;
}

.flatpickr-months .flatpickr-month {
    background: #004b83 !important;
}

span.flatpickr-weekday {
    background: #004b83 !important;
}

.flatpickr-weekdays {
    background: #004b83 !important;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #004b83 !important;
   }

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #004b83 !important;
   }

.flatpickr-monthDropdown-months {
    background: #004b83 !important;
}

.ReactTable .-pagination input {
    width: 2vw !important;
    font-size: 0.9vw !important;
}
/* .ReactTable .-pagination .-pageJump input {
    width: 70px;
    text-align: center;
} */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}
/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0.5vw rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 0.5vw;
}

::-webkit-scrollbar
{
	width: 0.5vw;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 0.5vw;
  background-color: #333333;
} */

.react-confirm-alert-button-group button:hover {
    background-color: #333333;
    opacity: 0.8;
}

.react-confirm-alert-button-group button:hover {
    background-color: #333333;
    opacity: 0.8;
}

.react-confirm-alert-body {
    width: 50vw !important;
    box-shadow: 0px 1vw 2vw 0.5vw rgba(0, 0, 0, 0.5) !important;
}

#disclaimer {
    font-size: 1vw;
    text-align: justify;
}

h1 {
    font-size: 2vw !important;
}

.disclaimer-button {
    margin-top: 32px;
    font-family: 'Inter';
    /* border-radius: 2vw; */
    /* background-color: #333; */
    border: none;
    font-size: 1vw;
    color: #fff;
    padding: 0.5vw 0.75vw;
    margin-right: 1vw;
    cursor: pointer;
    position: relative;
    /* left: 39.25vw; */
}

.react-confirm-alert-body h1 {
    font-family: "Inter-Bold";
    text-align: center;
}

#disc-button-container {
    display: flex;
    justify-content: center;
}

#accept {
    background-color: #004b83;
    /* position: relative; */
    /* right: -10vw; */
}

#refuse {
    background-color: #333;
    /* position: relative; */
    /* left: 0vw; */
}

.disclaimer-button:hover {
    opacity:0.8;
}


@media only screen and (max-width: 600px) {
    .dateSelector {   
        font-size: 4vw;
    }
    
    
    .startDate {
        display:flex;
        flex-direction:column;
        margin: 2vw;
        padding-top: 2.75vw;
    }

    .flatpickr-input {
        height: 3vw;
        width: 30vw;
        border: 0.2vw solid #333333;
        font-size: 2vw;
    }

    .flatpickr-input[readonly] {
        font-size: 2vw;
    }

    .container {
        font-size: 2.5vw;
    }

    ul.ks-cboxtags {
        padding: 2vw;
    }
    
    ul.ks-cboxtags li label {
        padding: 0.8vw 1.2vw;
    }

    ul.ks-cboxtags li label {
        border: 0.2vw solid rgba(139, 139, 139, .3);
        border-radius: 4vw;
    }

    .searchTerm {
        border: 0.3vw solid #333333;
        padding: 0.6vw;
        height: 3.5vw;
        /* border-radius: 1vw 0 0 1vw; */
        font-size: 2vw;
    }

    .wrap {
        width: 50vw;
        padding-bottom: 3vw;
    }

    .searchButton {
        width: 6vw;
    }

    ul.ks-cboxtags-2 {
        font-size: 1.6vw;
    }

    ul.ks-cboxtags-2 li label {
        padding: 1.25vw 1.75vw;
    }
    
    .details {
        width:95vw;
    }
    
    .summary {
        width: 95vw;
        cursor: pointer;
    }
    
    
    .tableHolder {
        display: inline-block;
    }

    .ReactTable {
        font-size: 2vw;
    }

    .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
        max-width: 60px !important;
    }

    .ReactTable .rt-tbody .rt-td {
        max-width: 60px !important;
    }
}

.csv-reader-input {
  display: flex;
  margin: auto;
  cursor: pointer;
}

.csv-reader-input label {
    cursor: pointer;
    padding: 1vw;
}

#csv-reader {
    /* width:; */
    display: flex;
text-align: center;
  padding: 1vw;
  margin: 0.5vw auto;  
  /* cursor: pointer; */
}

input[type="file"] {
    display: none;
}

.csv-reader-button {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 0.25vw 0.5vw;
    cursor: pointer;
}