#review-box {
    font-family: 'Inter';
    width: 40vw;
    font-size: 0.8vw;
    /* height: 470px; */
    padding-top: 1vw;
    padding-bottom: 2vw;
    padding-left: 5vw;
    padding-right: 5vw;
    /* margin: 7em auto; */
    /* border-radius: 1.5em; */
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    /* vertical-align: center; */
}

#content-container {
    display: flex;
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 1vw;
}

select{
    font-size:14px;
}
/* * {
    -webkit-transition: all 1s ease;
    -moz-transition:    all 1s ease;
    -ms-transition:     all 1s ease;
    -o-transition:      all 1s ease;
  } */

.review-form {
    /* width: 40vw; */
    display: inline-block;
    text-align: center;
    align-content: center;
    vertical-align: center;
    position: relative;
    /* left: 5vw; */
    align-self: center;
    width: 40vw;
    /* color: rgb(38, 50, 56); */
    font-weight: 700;
    /* font-size: 14px; */
    /* letter-spacing: 1px; */
    /* background: rgba(136, 126, 126, 0.20); */
    padding: 1vw 0.5vw;
    border: none;
    /* border-radius: 2vw; */
    outline: none;
    box-sizing: border-box;
    border: 0.2vw solid rgba(0, 0, 0, 0.20);
    margin-bottom: 2vw;
    /* margin-left: 46px; */
    text-align: center;
    /* margin-bottom: 27px; */
    font-family: 'Inter';
    transition: all 0.5s;
    
}

h2 {
    align-self: center;
    text-align: center;
}

.review-button {
    /* align-self: center; */
    /* align-items: center; */
    position: relative;
    left: -11vw;
    /* border-radius: 1vw; */
    border-style: solid;
    border: none;
    padding: 10px;
    border-width: 1;
    cursor: pointer;
    font-weight: 400;
    line-height: normal;
    /* display: inline-block; */
    /* margin: 0 0 1.25rem; */
    /* text-align: center; */
    /* display: inline-block; */
    padding: 0.5rem 1rem;
    font-size: 1vw;
    background-color: #004b83;
    color: white !important;
    border-color: #453D3F;
    /* color: #453D3F; */
}

.review-button-chilled {
    /* align-self: center; */
    /* align-items: center; */
    /* border-radius: 1vw; */
    border-style: solid;
    border: none;
    border-width: 1;
    cursor: pointer;
    font-weight: 400;
    line-height: normal;
    /* display: inline-block; */
    /* margin: 0 0 1.25rem; */
    /* text-align: center; */
    /* display: inline-block; */
    padding: 0.5rem 1rem;
    font-size: 1vw;
    background-color: #004b83;
    color: white !important;
    border-color: #453D3F;
    /* color: #453D3F; */
}


.ReactTable p {
    text-align: left !important;
}

#add-form:hover {
    background: #004b83ee;
}

#add-form::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 501;
    /* font-size: ; */
    padding-right: 0.5vw;
    content: "\f067";
    transition: transform .3s ease-in-out;
}

#submit-review::after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    /* font-weight: 100; */
    /* font-size: ; */
    padding-left: 0.5vw;
    content: "\f1d8";
    transition: transform .3s ease-in-out;
}

#submit-review:hover::after {
    font-weight: 900;
}

/* button:hover {
    background-color: #004b83cc;
} */

.elem-initial {
    opacity:0;
    max-height: 0;
}

#submit-review {
    background-color: #004b83;
    color: white;
    left: 11vw;
}

#submit-review:hover {
    background-color: #004b83ee;
}

select {
    /* border-radius: 1vw; */
    border-width: 0.07vw;
    padding: 0.1vw;
    padding-left: 0.5vw;
    border-color: black;
    color: black;
}

select:disabled {
    color: #333333;
    font-style: oblique;

}

.newValue {
    /* border-radius: 0.3vw; */
    border-top: 0vw;
    border-left:0vw;
    border-right: 0vw;
    padding: 0.1vw;
    padding-left: 0.5vw;
    border-color: black;
}

.newValue::placeholder {
    color: #333333;
    font-style: oblique;
}

.newParam {
    /* border-radius: 0.3vw; */
    border-top: 0vw;
    border-left:0vw;
    border-right: 0vw;
    padding: 0.1vw;
    padding-left: 0.5vw;
    border-color: black;
}

.newParam::placeholder {
    color: #333333;
    font-style: oblique;
}


.currValue {
    /* font-size: 1.25vw; */
    color: #333333;
    letter-spacing: 0em;
    font-weight: normal;
    font-style: oblique;
}

.rmspan {
    vertical-align: middle;
    display: inline-block;
}
.rm{
	height: 0;
	width: 0;
	visibility: hidden;
}

.note {font-weight: normal; letter-spacing: 0em; line-height: 100%;}

.rmlbl {
    vertical-align: middle;

	cursor: pointer;
	text-indent: -1000vw;
	width: 2.5vw;
	height: 1.25vw;
	background: grey;
	display: inline-block;
	/* border-radius: 1.25vw; */
	position: relative;
}

.rmlbl:after {
	content: '';
	position: absolute;
	top: 0.0625vw;
	left: 0.0625vw;
	width: 1.15vw;
	height: 1.15vw;
	background: #fff;
	/* border-radius: 1.15vw; */
	transition: 0.3s;
}

.rm:checked + .rmlbl {
	background: #dd4814;
}

.rm:checked + .rmlbl:after {
	left: calc(100% - 0.0625vw);
    transform: translateX(-100%);
}

.rmlbl:active:after {
	width: 1.625vw;
}

#popup-email {
    background: #004b83;
    color:white;
    left: 0vw;
}

#rm-action {
    background: #004b83;
    color:white;
    left: 0vw;
    /* align-self: center;
    align-items: center; */
    /* position: block !important; */
}

#rm-action:hover {
    background: #004b83ee;
}

#rm-action::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    /* font-weight: 501; */
    /* font-size: ; */
    padding-right: 0.5vw;
    content: "\f2ed";
    transition: transform .3s ease-in-out;
}

button:focus {outline:0;}
select:focus {outline:0;}
input:focus {outline:0;}
option:focus {outline:0;}
select:active, select:hover {
    outline-color: red
  }

/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0.5vw rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 1vw;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  background-color: #333333;
} */
