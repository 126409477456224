.tableHolderUserManagement {
    padding-top:10px;
    display: flex;
    margin: 0 auto;
    width: 70vw;
    align-items: center;
}

.user-button-cont-row {
    display: flex;
    justify-content: center;

}

.searchUserManagement {
    width: 20vw;
    margin: 30px auto;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 0.1vw solid #333333;
    border-right: none;
    padding: 0.3vw;
    height: 1.5vw;
    /* border-radius: 0.5vw 0 0 0.5vw; */
    outline: none;
    color: #333333;
    font-size: 0.75vw;
  }
  
  .searchTerm:focus{
    color: #333333;
  }
  
  .searchButton {
    width: 3vw;
    /* height: 2.35vw; */
    border: 0.1vw solid #333333;
    background: #333333;
    text-align: center;
    color: #fff;
    /* border-radius: 0 0.5vw 0.5vw 0; */
    font-size: 1vw;
    cursor: default;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    width: 20vw;
    margin: 0 auto;
    align-content: center;
    padding-bottom: 1vw;
  }


.ReactTable .-pagination input {
    width: 2vw !important;
    font-size: 0.9vw !important;
}
/* .ReactTable .-pagination .-pageJump input {
    width: 70px;
    text-align: center;
} */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}
/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0.5vw rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 0.5vw;
}

::-webkit-scrollbar
{
	width: 0.5vw;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 0.5vw;
  background-color: #333333;
} */

.create-user-button {
    font-family: 'Inter';
    font-size: 18px;
    padding: 5px 10px ;
    /* position: absolute; */
    /* opacity: 0; */
    background-color: #004b83;
    color: white;
    border: none;
    cursor: pointer;
}

.create-user-button:hover {
    opacity: 0.8;
}

.edit-user-col1 {
    order: 1;
}

.edit-user-col2 {
    order: 2;
}

.edit-user-container {
    width: 50vw;
    border-radius: 25px;
    box-shadow: 0px 1vw 2vw 0.5vw rgba(0, 0, 0, 0.5);
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.edit-user-col-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.edit-user-text-input {
    margin-left: 10px;
}

#groups li {
    list-style: none;
}

#groups p {
    font-weight: bold;
}

.user-buttons {
    margin-top: 32px;
    font-family: 'Inter';
    border: none;
    font-size: 1vw;
    color: #fff;
    padding: 0.5vw 0.75vw;
    margin-right: 1vw;
    cursor: pointer;
    position: relative;
}

.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.95);
}

#reset-user-password {
    background-color: #444B6E;
}

#reset-user-account {
    background-color: #22333B;
}

#disable-user {
    background-color: #3F4B3B;
}

.user-buttons:hover {
    opacity:0.8;
}

#submit-user-changes {
    background-color: #5A9367;
}

.top-buttons-container {
    display: flex;
    flex-direction: row; 
    justify-content: center;
}

#create-new-user {
    order: 1;
    background-color: #004b83;
}

#refresh-table {
    order: 2;
    background-color: #004b83;
}




@media only screen and (max-width: 600px) {
    

    .container {
        font-size: 2.5vw;
    }

   
    .searchTerm {
        border: 0.3vw solid #333333;
        padding: 0.6vw;
        height: 3.5vw;
        border-radius: 1vw 0 0 1vw;
        font-size: 2vw;
    }

    .wrap {
        width: 50vw;
        padding-bottom: 3vw;
    }

    .searchButton {
        width: 6vw;
    }

    
    .details {
        width:95vw;
    }
    
    .summary {
        width: 95vw;
        cursor: pointer;
    }
    
    
    .tableHolder {
        display: inline-block;
    }

    .ReactTable {
        font-size: 2vw;
    }

    .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
        max-width: 60px !important;
    }

    .ReactTable .rt-tbody .rt-td {
        max-width: 60px !important;
    }
}

input[type="file"] {
    display: none;
}

.csv-reader-button {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 0.25vw 0.5vw;
    cursor: pointer;
}

.edit-user-button {
    background-color: #004b83;
    color: white;
    border: none;
    padding: 0.2em;
    padding-left: 1em;
    padding-right: 1em;
    cursor: pointer;
    margin: 0px;
    height: 100%;
}

.edit-user-button:hover {
    opacity: 0.8;
}

#h4-nopad {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    /* margin-bottom: 0px; */
}