.sideBarColor {
    background-color: #004b83 !important;
}

.guide-container {
    width: 50vw;
    margin-left: calc(140px + 20vw);
    margin-right: calc(20px + 20vw)
}


.main-help h2 {
    font-family: 'Inter-Bold';
    font-size: 35px;
    font-weight: 500;

 }

 .main-help {
    margin-top: 100px;
 }

 .guide-body h2 {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 23px;
    font-weight: normal;
    text-align: left;
 }

 .guide-body p {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
 }

 .guide-body li {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
 }

 .guide-body h1 {
    font-family: 'Inter-Bold', serif;
    font-size: 30px !important;
    font-weight: 500;
    color: black;
    text-transform: uppercase;
 }

 .guide-body {
    font-family: Arial, 'Inter', sans-serif;
    font-size: 16px;
    font-weight: lighter;
 }

 .main-help a {
     color: #614201;
 }

 .sideBar {
      position: fixed;
      float: left;
      height: 100%;
      top: 65px;
 }

 /* .main-help p {
    text-align: left;
    vertical-align: left;
 } */

#table-of-contents {
    /* position: -webkit-sticky; */
    position: fixed;
    left:2.5vw;
    top: 100px;
    /* top: calc(5vw+65px); */
    background: #f1cb77;
        /* height: ; */
        /* margin: 7em auto; */
        font-family: 'Inter', sans-serif;
        width: 12vw;
        /* height: 470px; */
        /* padding-top: 1vw; */
        /* padding-bottom: 2vw; */
        padding-left: 1vw;
        padding-right: 1vw;
        /* margin: 7em auto; */
        border-radius: 1em;
        /* box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.3); */
        text-align: left;
        vertical-align: center;
        font-size: 1vw;
}

#table-of-contents a {
    color: #444444;
}

.p2 {
    font-size: 0.85em;
    font-weight: 100;
    position: relative;
    /* right: 0.25vw; */
    font-style: oblique;
}

#table-of-contents p {
    /* color: #614201; */
    /* font-weight: 400; */
    text-align: left;
    vertical-align: right;
}