/* body {
    background: black;
} */

/* Preload images */
body::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content:url("../images/greenButton.png");
 }

#home-cont {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    /* padding-top: 10px; */
    width: 50%;
    /* height: fill; */
}

#home-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* background: black; */
    height: calc(100vh - 125px);
}

.banner {
    /* display: flex; */
    background: url("../images/cover-photo.png");
    width: 100%;
    /* height: 250px; */
    background-position: 50% 70%;
    /* Make the background image cover the area of the <div>, and clip the excess */
    background-size: cover;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.headerText {
    color: white !important;
    z-index: 5;
    font-size: calc(24px + 2vw);
    margin: 0 auto;
    color: black;
    font-family: "Inter-Bold";
    /* text-align: center; */
    /* padding-top: 85px; */
    /* padding-left: 3.5vw; */
}


.tile {
    cursor: pointer;
    order: 1;
    /* background-color: #f2d2b8; */
    /* border-radius: 50px; */
    width: 250px;
    color: #041144;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    /* border: 3px solid #041144; */
}

.tile:hover {
    opacity: 0.9;
    /* transform: scale(1.02); */
    /* transition: all .5s; */
}

.tile:hover h2 {
    color: #be5229;
    text-decoration-color: #041144;
}

.tile-content {
    /* background: rgb(0, 0, 0, 0.4); */
    position: relative;   
    /* border-radius: 0em 1.5em 1.5em 0em; */
    font-family: "Inter-Bold";
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tile-content h2 {
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    font-size:calc(8px + 0.8vw);
    margin-bottom: 25px;
    text-decoration: underline;
    text-decoration-color: #be5229;
    text-decoration-thickness: 3px;
    height: 50px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5;
}

.tile-content h3 {
    font-weight: normal;
    font-size:calc(8px + 0.4vw);
    font-family: "Inter", sans-serif;
    text-align: center;
    height: 100px;
    width: 250px
}

.tile-img {
    width: 30vw;
}

.tile-header {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.divider {
    background-color: #041144;
    width: 1px;
    height: 200px;
}

.divider-spacer {
    /* background-color: #041144; */
    width: 1px;
    /* height: 200px; */
}

.divider-vertical {
    background-color: #041144;
    height: 1px;
    width: 250px;
    margin-top: 30px;
    margin-bottom: 30px;
    /* height: 200px; */
}